import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from './churn.svg'; // Assuming your SVG is named logo.svg

// Styled components
const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0B0C0D;
`;

const CenteredLogo = styled(Logo)`
  width: auto;
  height: auto;
  fill: #fff;
`;

function App() {
  return (
    <AppContainer>
      <CenteredLogo />
    </AppContainer>
  );
}

export default App;